// light, regular
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Light.ttf') format('woff2'),
    url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: lighter;
}
// light, italic
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-LightItalic.ttf') format('woff2'),
    url('../assets/fonts/Poppins-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: lighter;
}

// normal, regular
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf') format('woff2'),
    url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

// medium,
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Medium.ttf') format('woff2'),
    url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-style: medium;
  font-weight: 500;
}

// medium, italic
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-MediumItalic.ttf') format('woff2'),
    url('../assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 500;
}

// semi-bold, regular
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBold.ttf') format('woff2'),
    url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-style: regular;
  font-weight: 600;
}

// semi-bold, italic
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('woff2'),
    url('../assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 600;
}

// bold, regular
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Bold.ttf') format('woff2'),
    url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-style: regular;
  font-weight: bold;
}
// bold, italic
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-BoldItalic.ttf') format('woff2'),
    url('../assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

// bolder, regular
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Black.ttf') format('woff2'),
    url('../assets/fonts/Poppins-Black.ttf') format('truetype');
  font-style: regular;
  font-weight: 800;
}
// 800, italic
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-BlackItalic.ttf') format('woff2'),
    url('../assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
}
