@import '../../theme/colors';

.header-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: center;
  flex-wrap: wrap;
  background-color: $PRIMARY-COLOR;

  @media (max-width: 550px) {
    justify-content: center;
    height: 20vh;
    padding-top: 5%;
  }
}

.left-div {
  flex: 1 1 0px;

  margin-top: 1.5rem;
  margin-left: 1rem;

  @media (max-width: 478px) {
    margin: 0 30%;
  }
}

.right-div {
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  max-width: 20%;

  @media (max-width: 1078px) {
    margin: 0 2% 0 35%;
    // max-width: 100%;
  }
  @media (max-width: 478px) {
    margin: 0 35%;
    max-width: 100%;
  }
}

.login-right-div {
  flex: 1 1 0px;
  display: flex;
  justify-content: flex-end;
  background-color: $WHITE-COLOR;
  margin: -3em;

  @media (max-width: 750px) {
    background-color: $SOLTICE-PURPLE;
    margin: 1em;
  }
}

.faq-text {
  color: $WHITE-COLOR !important;
  text-decoration: underline;
  font-weight: 550 !important;

  @media (max-width: 450px) {
    font-size: 16px !important;
  }
}

.responsive {
  width: 100%;
  max-width: 160px;
}

.mobile-logo {
  display: flex;
  align-items: center;
  background-color: $SOLTICE-PURPLE;
  padding: 5%;
}

.mobile-logo-image {
  flex: 2;
}

.hamburger-menu {
  flex: 1;
  .bar-one,
  .bar-two {
    width: 2rem;
    margin-bottom: 7.5px;
    height: 3px;
    background: #fff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .bar-three {
    width: 2rem;
    height: 3px;
    background: #fff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .bar-one {
    transform: rotate(0);
  }

  .bar-two {
    opacity: 1;
    transform: translateX(0);
  }

  .bar-three {
    transform: rotate(0);
  }

  .bar-one-transform {
    transform: rotate(45deg);
  }

  .bar-two-transform {
    opacity: 0;
    transform: translateX(20px);
  }

  .bar-three-transform {
    transform: rotate(-45deg);
  }
}

.hamburger-menu-list {
  background-color: $SOLTICE-PURPLE;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
