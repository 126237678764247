/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
$spacer: 1rem !default;
@import 'fonts';
@import 'utils';
@import 'base';
@import 'button';

* {
  font-family: $headings-font-family;
}
