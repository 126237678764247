// thin, regular
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Hairline.ttf') format('woff2'),
    url('../assets/fonts/Lato-Hairline.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}
// thin, italic
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-HairlineItalic.ttf') format('woff2'),
    url('../assets/fonts/Lato-HairlineItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 200;
}

// light, regular
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Light.ttf') format('woff2'),
    url('../assets/fonts/Lato-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: lighter;
}
// light, italic
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-LightItalic.ttf') format('woff2'),
    url('../assets/fonts/Lato-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: lighter;
}

// normal, regular
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato.ttf') format('woff2'),
    url('../assets/fonts/Lato.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

// bold, regular
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Bold.ttf') format('woff2'),
    url('../assets/fonts/Lato-Bold.ttf') format('truetype');
  font-style: regular;
  font-weight: bold;
}
// bold, italic
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BoldItalic.ttf') format('woff2'),
    url('../assets/fonts/Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

// bolder, regular
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-Black.ttf') format('woff2'),
    url('../assets/fonts/Lato-Black.ttf') format('truetype');
  font-style: regular;
  font-weight: 800;
}
// 800, italic
@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato-BlackItalic.ttf') format('woff2'),
    url('../assets/fonts/Lato-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
}
