.font-primary {
  color: #6c4a97;
}

.font-bold {
  font-weight: bolder;
}

.font-grey {
  color: #616060;
}
