@import '../../theme/colors';

.confirmation-text {
  font-style: normal;
  font-weight: 600;
  line-height: 108px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f3f85 !important;
  font-size: 5rem;

  @media (max-width: 900px) {
    font-size: 3rem;
  }
}

.helpLink {
  color: #4f3f85 !important;
}

.anotherPaymemtbutton {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px !important;
  width: 100% !important;
  font-size: 0.8rem !important;
}


.bold-text {
  font-size: 2.5rem;
}

.bolder-text {
  font-size: 4.5rem;

  @media (max-width: 900px) {
    font-size: 2.5rem;
  }
}

.header-text {
  font-weight: 600;
  padding-bottom: 1rem;
  font-size: 1.2rem !important;
  width: 100%;
  text-align: left;

  @media (max-width: 900px) {
    font-size: 0.5rem;
  }
}

.header-text-success {
  @extend .header-text;
  color: $WEB-PORTAL-YELLOW !important;
  text-align: center;
}

.header-text-failure {
  @extend .header-text;
  color: #4f3f85 !important;
  text-align: center;
  padding: 0 5% 0 5%;
}

.button {
  margin-top: 3em;
  width: 50% !important;
  height: 3em !important;

  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.logout-button {
  color: $WEB-PORTAL-YELLOW !important;
  font-size: 16px !important;
  margin-top: 5px !important;
}

.confirmation-div {
  margin: 0 auto;
  padding-top: 5%;

  @media (max-width: 900px) {
    margin: 0 auto;
    padding-top: 10%;
  }

  @media (max-width: 700px) {
    padding-top: 8%;
    margin: 0 auto;
  }
}