// Core Colors
$CEMENT-WHITE: #f3f3f3;

$AFTERNOON-SHADOW: #e3e5e6;

$COOL-SUMMER-GRAY: #acb4b9;

$DUSK-GRAY: #75828a;

$SUMMER-NIGHT: #292a2f;

$LIGHT-COOL-YELLOW: #faf4cd;

$PALE-GOLD: #ede6ae;

$WARM-YELLOW: #f2c345;

$RAW-SIENNA: #d2834a;

$SOLTICE-PURPLE: #201640;

$PRIMARY-COLOR: $SOLTICE-PURPLE;

$WHITE-COLOR: #ffffff;

$INFO-GRAY: #9d9898;

// Indicator colors

$STATUS-GREEN: #b4eb86;

$STATUS_YELLOW: $WARM-YELLOW;

$STATUS_RED: #d76b6b;

$DANGER_RED: #ed5615;

// Extra color
$REEF_GREEN: #d1e9a6;

$DARK_GREEN: #5e803f;

$CUSTOM_PINK: #f2b4b9;

$FIERY_ORANGE: #be4f12;

$DARK-PURPLE: #79738c;

$LIGHT-PURPLE: #e4e3e8;

$WEB-PORTAL-YELLOW: #f2c94c;
