@import './colors';

.ant-alert-message {
  font-size: 12px;
  font-weight: normal;
  color: inherit;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  border-radius: 5px;
  background-color: $WHITE-COLOR;
  color: inherit;
  font-weight: 600;
}

.ant-input-number {
  width: 100%;
  padding: inherit;
}

.ant-steps-item {
  @media (max-width: 900px) {
    max-width: 25%;
  }
  @media (max-width: 455px) {
    max-width: 20%;
  }
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: $SOLTICE-PURPLE;
  font-size: 16px;
  font-weight: 500;
}

.ant-steps-vertical.ant-steps-dot
  .ant-steps-item-process
  .ant-steps-item-container
  .ant-steps-item-icon
  .ant-steps-icon-dot,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  width: 15px;
  height: 15px;
  left: -4px;
  background-color: $WEB-PORTAL-YELLOW;

  @media (max-width: 900px) {
    margin-top: 13px;
  }
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  height: 1px;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background-color: #c4c4c4;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0 !important;
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
  @media (max-width: 590px) {
    margin-top: -18px;
    margin-left: 1px !important;
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $WEB-PORTAL-YELLOW;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: $WEB-PORTAL-YELLOW;
  width: 15px;
  height: 15px;
  left: -4px;
}

.ant-select-selection-item {
  color: $SOLTICE-PURPLE;
  font-size: 16px;
  font-weight: 600;
}

.ant-form-vertical .ant-form-item-label > label {
  font-weight: normal;
  color: inherit !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 4px;
}

.ant-select-selection-item {
  color: #5a4892 !important;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
  border-radius: 5px;
}
.ant-input-affix-wrapper > input.ant-input {
  direction: inherit;
}

.ant-typography {
  color: inherit;
}

.anticon-info-circle {
  background-color: #e7e6e6;
  color: #fff;
  border-radius: 50%;

  @media (max-width: 590px) {
    opacity: inherit;
  }
}

.ant-input-number-input {
  font-weight: inherit;
  font-size: inherit;
}

.ant-modal {
  @media (max-width: 765px) {
    margin-left: 1% !important;
  }
}

.ant-modal-content {
  margin: inherit;
  border-radius: inherit;
}
.ant-modal-close-x {
  display: inherit;
}

.ant-modal-header {
  background-color: $SOLTICE-PURPLE;
  min-height: 60px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-footer {
  visibility: hidden;
}

.ant-btn-loading-icon {
  position: absolute !important;
  margin: 0 15% !important;
  color: #000 !important;
}

.ant-steps-item-description {
  color: #b91212 !important;
  font-size: 12px;
}
