@mixin button-styles($bg-color, $text-color) {
  font-weight: 500;
  font-size: 0.9rem;
  height: 43px;
  transition: transparent;
  margin: 0 auto;
  #{--background}: $bg-color;
  #{--background-activated}: $bg-color;
  #{--background-focused}: $bg-color;
  #{--background-activated-opacity}: $bg-color;
  #{--ripple-color}: transparent;
  #{--transition}: transparent;
  #{--color}: $text-color;
  #{--border-radius}: 0.25rem;
}

.button-purple {
  @include button-styles(#55467c, $CEMENT-WHITE);
}

.button-orange {
  @include button-styles($WARM-YELLOW, $SUMMER-NIGHT);
}

.button-gray {
  @include button-styles(#c4c4c4, $SUMMER-NIGHT);
}

.button-text-capitalize {
  text-transform: capitalize;
}

.button-text-uppercase {
  text-transform: uppercase;
}
