// light, regular
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: lighter;
}
// light, italic
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-LightItalic.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: lighter;
}

// normal, regular
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

// bold, regular
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-style: regular;
  font-weight: bold;
}
// bold, italic
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

// bolder, regular
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Black.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
  font-style: regular;
  font-weight: 800;
}
// 800, italic
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-BlackItalic.ttf') format('woff2'),
    url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: 800;
}
