.page-text {
  font-style: normal;
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 6rem;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 3% 15% 0 24% !important;
  color: #4f3f85 !important;

  @media (max-width: 765px) {
    font-size: 2.5rem;
    line-height: 4rem;
    margin: 5% 0 !important;
  }
}
