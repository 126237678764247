@import 'colors';
$primary-font: 'Poppins';
@import '../theme/_typography.scss';
@import 'fonts';

.menu-bar {
  background: #201640;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
}

.header-md::after {
  background-image: none !important;
}

.white-bg {
  background-color: $WHITE-COLOR;
}

.absolute {
  position: absolute;
}

.home-menu-bar-button {
  color: #f2f2f2;

  &:hover {
    color: #f2f2f2;
  }

  &:focused {
    color: #f2f2f2;
  }
}

.page-fab-button-orange {
  background: orange;
}

.menu-header {
  flex: 1;
}

.menu-body {
  flex: 1;
  justify-content: space-between;
  background-color: $WHITE-COLOR;
}

.menu-logo-wrapper {
  width: 50%;
  align-self: center;
  margin-bottom: 5px;
}

.menu-logo {
  height: 50px;
  max-width: 100%;
  resize-mode: contain;
}

.menu-header-toolbar-primary {
  background: #201640;
  color: #e3e5e6;
}

.page-content-card-title {
  font-weight: 600;
  letter-spacing: 0.1rem;
  font-size: 1.2rem;
  color: #201640;
}

.page-title {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}

.menu-content-afternoon-shadow {
  background: $WHITE-COLOR;
}

.list-md {
  padding: 0;
}

.menu-list-header-bg-primary {
  background: #e3e5e6;
}

.menu-list-header-border-primary {
  border-bottom: 1px solid #201640;
}

.top-30 {
  top: 30%;
}

.menu-header-item {
  background: $WHITE-COLOR;
  color: #75828a;
  font-family: $headings-font-family;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
}

.menu-item-row {
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-width: 1px;
  border-bottom-color: rgb(227, 229, 230);
}

.menu-title {
  text-align: left;
  color: rgb(227, 229, 230);
  padding: 12px;
  padding-left: 20px;
  font-size: 16px;
  font-family: NunitoSans-Regular;
}

.menu-item {
  text-align: left;
  color: rgb(117, 130, 138);
  font-size: 14px;
  font-family: NunitoSans-Regular;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.menu-header-icon {
  color: rgb(172, 180, 185);
  font-size: 24px;
}

.menu-label-primary {
  color: #201640;
  color: #201640;
  font-family: $global-font;
}

.menu-list-afternoon-shadow {
  background: $WHITE-COLOR;
}

.page-toolbar-title {
  display: flex;
  justify-content: center;
  text-align: -webkit-center;
}

.page-toolbar-image {
  max-width: 94px;
  width: 100%;
  height: 20px;
}

.page-content-grid-padding-0-margin-0 {
  padding: 0;
  margin: 0;
}

.page-content-row-primary {
  background-color: #201640;
}

.page-content-extra-purple-upper {
  margin-top: -100vw;
  padding-top: 100vw;
}

.page-content-row-height-30 {
  height: 30vh;
}

.page-content-absolute-row {
  position: absolute;
  width: 70%;
  height: 100px;
  top: calc(50% - (13.5vh / (7 / 8)));
  left: calc(50vw - (70% / 2));
}

.page-content-absolute-row-ios {
  position: absolute;
  width: 70%;
  height: 100px;
  top: calc(55% - (13.5vh / (7 / 8)));
  left: calc(50vw - (70% / 2));
}

.page-content-absolute-row-desktop {
  position: absolute;
  width: 70%;
  height: 100px;
  top: calc(48% - (13.5vh / (7 / 8)));
  left: calc(36vw - (70% / 2));
}

.page-content-absolute-col {
  background-color: $WHITE-COLOR;
  border-radius: 10px;
  border: 1px solid rgba(#000000, 0.3);
}

.h-full {
  height: 100%;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.m-1 {
  margin: 1rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 1.5rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 1.5rem;
}

.mt-1-2 {
  margin-top: 0.5rem;
}

.mt-small {
  margin-top: 0.5rem;
}

.pb-small {
  padding-bottom: 0.5rem;
}

.p-small {
  padding: 0.6rem;
}

.pb-extra-small {
  padding-bottom: 0.3rem;
}

.pl-small {
  padding-left: 0.6rem;
}

.pt-small {
  padding-top: 0.5rem;
}

.pl-small {
  padding-left: 0.5rem;
}

.pr-small {
  padding-right: 0.5rem;
}

.ml-small {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mr-extra-small {
  margin-right: 0.2rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-1 {
  margin-left: 1rem;
}

.mt-3 {
  margin-top: 3rem;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-small {
  margin-right: 0.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-1_5 {
  padding-bottom: 1.5rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pb-1 {
  padding-bottom: 0.7rem;
}

.pb-4 {
  padding-bottom: 4rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-0_5 {
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 2rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.relative {
  position: relative;
}

.flex-1 {
  flex: 1;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.flex-none {
  flex: none;
}

.text-white {
  color: #fdfafa;
}

.text-soltice-purple {
  color: $PRIMARY-COLOR;
}

.text-black {
  color: #000;
}

.text-gray-1 {
  color: #979797;
}

.text-gray-2 {
  color: #75828a;
}

.bg-gray-2 {
  background-color: #d2cfcf;
}

.bg-cement-white {
  background-color: $CEMENT-WHITE;
}

.nunito {
  font-family: Nunito Sans;
}

.text-lg {
  font-size: 16px;
  font-style: normal;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-base {
  font-size: 14px;
  font-style: normal;
}

.text-sm {
  font-size: 11px;
  font-style: normal;
}

.text-17 {
  font-size: 17px;
}

.text-13 {
  font-size: 13px;
}

.text-12 {
  font-size: 12px;
  font-style: normal;
}

.text-20 {
  font-size: 20px;
}

.text-xs {
  font-size: 10px;
  font-style: normal;
}

.text-xxs {
  font-size: 9.5px;
  font-style: normal;
}

.font-bold {
  font-weight: bold;
}

.font-300 {
  font-weight: 300;
}

.uppercase {
  text-transform: uppercase;
}

.spacing-small {
  letter-spacing: 0.38px;
}

.spacing-normal {
  letter-spacing: 0.5px;
}

.spacing-medium {
  letter-spacing: 0.7px;
}

.spacing {
  letter-spacing: 1.55px;
}

.spacing-large {
  letter-spacing: 1.7px;
}

.spacing-tight {
  letter-spacing: -0.72353px;
}

.shadow {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.1);
}

.bg-primary-light {
  background: rgba(32, 22, 64, 0.8);
}

.rounded-sm {
  border-radius: 8px;
}

.lato {
  font-family: Lato;
  font-style: normal;
}

.border-top {
  border-top: 1px solid #d2cfcf;
}

.border-bottom-top {
  border-top: 1px solid #75828a;
}

.primary-font {
  font-family: $primary-font;
}

.font-size-0_8 {
  font-size: 0.8rem;
}

.fontSize0_9 {
  font-size: 0.9rem;
}

.font-size-1 {
  font-size: 1rem;
}

.font-size-1_1 {
  font-size: 1.1rem;
}

.font-size-1_5 {
  font-size: 1.5rem;
}

.font-size-2 {
  font-size: 2rem;
}

.font-size-2_5 {
  font-size: 2.5rem;
}

.text-underline-orange {
  color: #cf7833;
  text-decoration: underline;
}

.font-color-black {
  color: #000;
}

.font-weight_100 {
  font-weight: $FONT_WEIGHT_100;
}

.font-weight_200 {
  font-weight: $FONT_WEIGHT_200;
}

.font-weight_300 {
  font-weight: $FONT_WEIGHT_300;
}

.font-weight_400 {
  font-weight: $FONT_WEIGHT_400;
}

.font-weight_500 {
  font-weight: $FONT_WEIGHT_500;
}

.font-weight_600 {
  font-weight: $FONT_WEIGHT_600;
}

.font-weight_700 {
  font-weight: $FONT_WEIGHT_700;
}

.ReactModal__Overlay {
  background-color: rgba(41, 42, 47, 0.5) !important;
}

.secondaryColor {
  color: #75828a;
}

.h-avatar {
  height: 44px;
}

.w-avatar {
  width: 44px;
}

.rounded-full {
  border-radius: 100%;
}

.bg-afternoon-shadow {
  background-color: $AFTERNOON-SHADOW;
}

.border-bottom {
  border-bottom: 1px solid rgba(#000, 0.1);
}

.padding-start-0 {
  padding-start: 0;
}

.padding-end-0 {
  padding-end: 0;
}

.text-button-dark {
  color: rgba(32, 22, 64, 0.45);
}

.max-width-popover {
  .popover-content {
    min-width: 250px;
    min-width: 250px;
  }
}

.text-summer-night {
  color: $SUMMER-NIGHT;
}

.text-danger {
  color: #ef5411 !important;
}

.text-black {
  color: #000000;
}

.bg-soltice-purple {
  background-color: $SOLTICE-PURPLE;
}

.border-none {
  border: none;
  border-style: none;
  border-width: 0;
  border-color: transparent;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.required {
  color: #ec445a;
}

ion-toggle {
  background: #a194c9;
  -webkit-tap-highlight-color: transparent;
}
