@import '../../theme/colors';
@import '../../theme/font-defaults';

.check-icon {
  color: $WHITE-COLOR !important;
  font-size: x-large;
  font-weight: 900;
}

.button {
  box-sizing: border-box;
  border-radius: 3px;
}

.check-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 !important;
  border: 0 !important;
  background-color: $WHITE-COLOR !important;
  text-align: left;
  white-space: normal;
}

.check-container-active {
  opacity: 1;
}

.check-container-inactive {
  opacity: 0.4;
}

.check-box {
  width: 28px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
}

.check-box-active {
  background-color: #55467c;
}

.check-box-inactive {
  background-color: $WHITE-COLOR;
  border: 0.75px solid #55467c;
}

.check-name {
  font-family: $font-family-poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  align-items: center;
  color: #767171 !important;
}
