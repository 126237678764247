.footer-div {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 2% 0;
  min-height: 10vh;
}

.footer-text {
  color: #e5e5e5 !important;
  font-size: 12px;
  text-align: center;
  max-width: 220px;
  margin: 1px 0;
}

.footer-responsive-logo {
  width: 100%;
  max-width: 70px;
  height: auto;
  margin: 1.5% auto;
}
