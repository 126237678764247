@import '../../../theme/colors';
@import '../../../theme/font-defaults';

.dashboard-div {
  margin: 5%;
  display: flex;
  flex-direction: column;
}

.dashboard-div-desktop {
  margin: 5%;
  display: flex;
  flex-direction: row;
}
.dashboard-div-mobile {
  margin: 5%;
  display: flex;
  flex-direction: column-reverse !important;
}

// payment review
.header-text {
  color: $WEB-PORTAL-YELLOW !important;
  font-weight: 600;
  padding-bottom: 1rem;
  font-size: 1.5rem !important;
}

.sub-heading-text_ {
  color: $INFO-GRAY !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  font-family: $font-family-poppins;
}

.review-row-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #9d9898;
}

.review-block-left {
  @extend .review-row-text;
  font-family: $font-family-poppins !important;
  .learn-more-text {
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    cursor: pointer;
    color: #c4c4c4;
  }
}

.review-block-right {
  @extend .review-row-text;
  display: flex !important;
  justify-content: flex-end;
  .right-amount-text {
    font-family: $font-family-poppins !important;
    line-height: 19px;
  }
}

.total-text,
.total-value {
  font-family: $font-family-poppins !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #9d9898;
}

.review-box-transfer-payment {
  background-color: #e5e5e5;
  padding: 19px 23px !important;
  .energy-credit-header {
    @extend .review-row-text;
    font-family: $font-family-poppins !important;
    font-weight: 600;
    color: #4f3f85;
  }
  .review-block-left {
    @extend .review-row-text;
    font-family: $font-family-poppins !important;
    color: #000000;
  }
  .review-block-right {
    .right-amount-text {
      font-family: $font-family-poppins !important;
      line-height: 19px;
      color: #201640;
    }
  }
  .review-bold {
    font-weight: bolder;
    color: #201640;
  }
}

.sub-total-block {
  width: 100%;
  margin-block: 41px;
  .sub-total-text {
    font-family: $font-family-poppins !important;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    color: #9d9898;
  }
  .sub-total-sub-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: #9d9898;
  }
  .sub-total-value {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: right;

    color: #201640;
  }
}

// payment review

.form-div {
  margin: 2% 0 !important;
  color: $SOLTICE-PURPLE !important;
}

.form-item {
  color: $SOLTICE-PURPLE !important;
}

.alert-div {
  color: #07652d !important;
  background-color: #b5edcb !important;
  margin-top: 1.9rem !important;
  margin-left: 1rem !important;
  border-radius: 5px !important;
  min-height: 40px;
  width: 350px;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 1.9rem !important;
    min-height: 40px;
    margin-left: 0 !important;
    width: 100%;
  }

  @media (max-width: 767px) {
    margin-top: -1rem !important;
    margin-bottom: 2rem !important;
    margin-left: 0 !important;
    width: 100%;
  }
}

.alert-div-error {
  color: #b91212 !important;
  background-color: #fed7d7 !important;
  margin-top: 2.3rem !important;
  margin-left: 1rem !important;
  border-radius: 5px !important;
  text-align: center;
}

.progress-div {
  margin-top: 8em;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 20%;

  @media (max-width: 900px) {
    margin-top: 0px;
    width: 100%;
  }
}

.progress-div-steps {
  display: flex !important;
  padding-left: 2rem;
  height: 100%;
  min-height: 50vh;

  @media (max-width: 900px) {
    padding-left: 0;
    min-height: 0;
  }

  @media (max-width: 600px) {
    margin-left: -3rem;
  }

  @media (max-width: 455px) {
    margin-left: -1.5rem;
  }
}

.progress-div-bottom {
  padding: 2rem 0 0 2rem;
  width: 100%;

  @media (max-width: 765px) {
    padding: 2rem 0 0 0;
  }

  button {
    min-width: 100%;
    width: 30px;
    margin-bottom: 2em;
  }
}

.progress-div-bottom-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $SOLTICE-PURPLE !important;
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  padding: 0 5px;
  margin-bottom: 20px;
}

.step-item {
  height: 120px;
  color: $SOLTICE-PURPLE !important;
  font-size: 16px;
}

.line-items {
  margin-top: 6.5em 0 !important;
}

.line-items-row {
  display: flex !important;
  justify-content: space-between;
  color: $SOLTICE-PURPLE !important;
  padding-right: 0.5rem !important;
}

.tooltip {
  margin: 0px 4px;
}

.input-style-disabled {
  color: #817c92 !important;
}

.line-items-amounts,
.line-items-amounts-total,
.line-items-amounts-total-label,
.line-items-input {
  font-weight: 600 !important;
  font-family: sans-serif !important;
}

.line-items-summary {
  display: flex !important;
  justify-content: flex-end !important;
}

.line-items-amounts,
.line-items-input {
  font-size: 16px !important;
  font-family: sans-serif !important;
}

.line-items-input {
  float: right;
  border-radius: 5px !important;
  padding: 3px 0 !important;
  font-family: sans-serif !important;

  .ant-select-selection-item {
    color: #000 !important;
  }
}

.line-items-amounts,
.line-items-amounts-total {
  padding-right: 20px;
}

.line-items-amounts-total-label,
.line-items-amounts-total {
  font-size: 20px !important;
}

.maximum-payment-alert {
  visibility: hidden;
}

#community-fee {
  .ant-select-selection-item {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.1rem;
    font-family: sans-serif !important;
  }
}
#energy-credit {
  .ant-input-number-input {
    text-align: right;
    padding-right: 1.5rem;
  }
}

#energy-credit-input {
  text-align: right;
  padding-right: 1.5rem;
}

.community-fee-select-option {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.iframe-loader {
  background-image: url('../../../assets/loader.svg');
  background-color: #e1e0e0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 43% 43%;
  border-style: none;
  min-height: 699px;
  margin-top: 3%;
}

.form-item-offset {
  @extend .form-item;
  margin-left: 1rem;

  @media (max-width: 767px) {
    margin-left: 0;
  }
}

// adjustment flutterwave inner iframe to be more responsive
.checkout {
  height: 110vh !important;
}
