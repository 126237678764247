@import '../../theme/colors';

.button {
  border-radius: 5px !important;
  font-size: inherit !important;
  width: 100%;
  height: inherit !important;
  font-size: 1.2rem !important;
}

.purple-white-button {
  @extend .button;
  background-color: $SOLTICE-PURPLE !important ;
  border-color: $SOLTICE-PURPLE !important ;
  color: $WHITE-COLOR !important;
  &:hover {
    background-color: none !important;
    border-color: none !important;
    color: $WEB-PORTAL-YELLOW !important;
  }
}

.yellow-button {
  @extend .button;
  background-color: $WEB-PORTAL-YELLOW !important ;
  border-color: $WEB-PORTAL-YELLOW !important ;
  color: $SOLTICE-PURPLE !important;
}

.yellow-button-purple {
  @extend .yellow-button;
  color: $SOLTICE-PURPLE !important;
}

.yellow-button-shadow {
  @extend .yellow-button;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
}

.yellow-button-shadow-white-color {
  @extend .yellow-button-shadow;
  color: $WHITE-COLOR !important;
  font-weight: 600 !important;
}

button[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}
