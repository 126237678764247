@import '../../theme/colors';

.crash-header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 4.5rem;
  line-height: 108px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #4f3f85 !important;
}

.crash-text {
  font-weight: 600;
  padding-bottom: 1rem;
  font-size: 1.5rem !important;
  width: 100%;
  text-align: center;
  color: #4f3f85 !important;
}
