@import '../../theme/colors';

.page {
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10%;

  @media (max-width: 550px) {
    min-height: 75vh;
  }

  // @media (max-width: 415px) {
  //   // min-height: 10vh;
  //   // max-height: 75vh;
  // }
}

.item-column {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  padding: '10% 2%';

  @media (max-width: 415px) {
    margin: -5rem 0;
  }
}

.mobile-item-column {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  padding: 2% 2%;
  margin: 0 auto;
}

.alert-error-div {
  background-color: #fed7d7 !important;
  color: #b91212 !important;
  margin: 0 auto 1% !important;
  border-radius: 5px !important;
  text-align: center;
  width: 90%;
}

.phone-placeholder {
  @media (max-width: 690px) {
    align-items: center;
  }
}

.responsive-image {
  width: 100%;
  max-width: 297px;
  height: auto;
  margin: 0 auto;

  @media (max-width: 912px) {
    padding-top: 40%;
  }

  @media (max-width: 690px) {
    padding-top: 50%;
  }

  @media (max-width: 520px) {
    padding-top: 100%;
  }

  @media (max-width: 340px) {
    padding-top: 170%;
  }
}

.mobile-welcome-text {
  color: #5a4892 !important;
  font-size: 30px;
  margin: 20% 15% 5% 15%;
  text-align: center;
}

.welcome-text {
  color: #f2f2f2 !important;
  font-size: 60px;
  padding: 20% 0;

  @media (max-width: 1097px) {
    font-size: 50px;
  }

  @media (max-width: 912px) {
    font-size: 40px;
    padding-top: 40%;
  }

  @media (max-width: 690px) {
    align-items: center;
    text-align: justify;
    font-size: 30px;
    padding-top: 50%;
  }

  @media (max-width: 520px) {
    font-size: 20px;
    padding-top: 100%;
  }
  @media (max-width: 390px) {
    font-size: 20px;
    padding-top: 120%;
  }
  @media (max-width: 340px) {
    font-size: 16px;
    padding-top: 170%;
  }
}

.sign-in-button {
  max-width: 25%;
  margin-top: -15%;
  font-size: 16px;
  height: 45px;

  @media (max-width: 790px) {
    max-width: 100%;
    align-items: center;
  }
}

.mobile-sign-in-button {
  max-width: 100%;
  font-size: 12px;
  height: 40px;
  align-items: center;
  margin: 5% auto;
}

.mobile-t-and-c-div {
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 2% 0;
  min-height: 10vh;
}

.mobile-t-and-c-text {
  color: #5a4892 !important;
  font-size: 12px;
  text-align: center;
  margin: 1px 0;
}

.guest-button {
  max-width: 25%;
  color: $WEB-PORTAL-YELLOW !important;
  text-decoration: underline;
  float: left;

  &:hover {
    color: #f2f2f2 !important;
  }
  @media (max-width: 690px) {
    align-items: center;
  }
}
